import { atomFamily, useRecoilValue, useSetRecoilState } from 'recoil';

export const playHeadShadowTime = atomFamily<number, string>({
  key: 'play-head-shadow-time',
  default: 0,
});

export const playHeadShadowVisibility = atomFamily<boolean, string>({
  key: 'play-head-shadow-visibility',
  default: false,
});

export const usePlayHeadShadowTime = (recordingId: string) => {
  return useRecoilValue(playHeadShadowTime(recordingId));
};

export const useSetPlayHeadShadowTime = (recordingId: string) => {
  return useSetRecoilState(playHeadShadowTime(recordingId));
};

export const usePlayHeadShadowVisibility = (recordingId: string) => {
  return useRecoilValue(playHeadShadowVisibility(recordingId));
};

export const useSetPlayHeadShadowVisibility = (recordingId: string) => {
  return useSetRecoilState(playHeadShadowVisibility(recordingId));
};
