import { array, object, string } from 'zod';

import { kogniaUserLocales } from 'api/backoffice/user/use-kognia-users/types';

export enum CreateUserFormFieldsNames {
  CLIENT_IDS = 'clientIds',
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  LOCALE = 'locale',
}

export const createUserFormSchema = object({
  [CreateUserFormFieldsNames.FIRST_NAME]: string().trim().min(2),
  [CreateUserFormFieldsNames.LAST_NAME]: string().trim().min(2),
  [CreateUserFormFieldsNames.CLIENT_IDS]: array(string()).min(1),
  [CreateUserFormFieldsNames.EMAIL]: string().email(),
  [CreateUserFormFieldsNames.LOCALE]: string().refine((val) => kogniaUserLocales.includes(val)),
});
