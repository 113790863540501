import { useEffect } from 'react';

import { useMetrics } from 'kognia/metrics/hooks/use-metrics';
import { ActionTypes } from 'shared/streams/actionTypes';
import { applicationEvents$ } from 'shared/streams/eventEmitter';

export const useSubscribeEvents = () => {
  const { pushEvent } = useMetrics();

  useEffect(() => {
    const subscription = applicationEvents$.subscribe((action) => {
      if (action.type === ActionTypes.SEND_METRICS) {
        pushEvent(action.payload.name, action.payload.data);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [pushEvent]);
};
