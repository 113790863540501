import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';

const sidebarOpenState = atom({
  key: 'isSidebarOpen',
  default: true,
});

export const useSidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useRecoilState(sidebarOpenState);

  const toggleSidebar = useCallback(() => setIsSidebarOpen(!isSidebarOpen), [setIsSidebarOpen, isSidebarOpen]);

  return {
    isSidebarOpen,
    toggleSidebar,
  };
};
