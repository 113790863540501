import { useCallback } from 'react';
import { atomFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

const overlayPanelOpenState = atomFamily<boolean, string>({
  key: 'is-overlay-panel-open',
  default: false,
});

export const useIsOverlayPanelOpen = (id: string) => {
  return useRecoilValue(overlayPanelOpenState(id));
};

const overlayPanelSelectedTab = atomFamily<number, string>({
  key: 'overlay-selected-tab',
  default: 0,
});

export const useOverlaySelectedTab = (id: string) => {
  return useRecoilValue(overlayPanelSelectedTab(id));
};

export const useSetOverlaySelectedTab = (id: string) => {
  return useSetRecoilState(overlayPanelSelectedTab(id));
};

export const useSetIsOverlayPanelOpen = (id: string, callback?: (isOpen: boolean) => void) => {
  const [isOverlayPanelOpen, setIsOverlayPanelOpen] = useRecoilState(overlayPanelOpenState(id));

  return useCallback(
    (open: boolean) => {
      if (isOverlayPanelOpen !== open) {
        callback?.(open);
        setIsOverlayPanelOpen(open);
      }
    },
    [callback, setIsOverlayPanelOpen, isOverlayPanelOpen],
  );
};
