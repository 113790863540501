import { object, string, TypeOf } from 'zod';

export type UserRole =
  | null
  | 'Video Analyst'
  | 'Head Coach'
  | 'Assistant Coach'
  | 'Strength Coach'
  | 'Physical Therapist'
  | 'Scouter'
  | 'Manager';

export const clientBaseSchema = object({
  id: string(),
  name: string(),
  city: string(),
  country: string(),
});

export const clientBrandingSchema = object({
  faviconUrl: string(),
  emailLogoUrl: string(),
  navbarLogoUrl: string(),
  navbarCollapsedLogoUrl: string(),
  displayName: string(),
});

export const clientSchema = clientBaseSchema.extend({
  branding: clientBrandingSchema.nullable(),
});

export type ClientBase = TypeOf<typeof clientBaseSchema>;
export type ClientBranding = TypeOf<typeof clientBrandingSchema>;
export type Client = TypeOf<typeof clientSchema>;

export enum UserAuthority {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_USER = 'ROLE_USER',
}

export interface User {
  id: string;
  clients: Client[];
  email: string;
  firstName: string;
  lastName: string;
  phoneAreaCode?: string;
  phoneNumber?: string;
  role: UserRole;
  avatarUrl?: string;
  locale: string;
  authorities: UserAuthority[];
}

export interface SeasonClients {
  id: string;
  originalClient: Client;
  seasonName: string;
  clients: Client[];
  updatedAt: Date;
}

export type FeatureFlags = {
  [key in string]: string | boolean;
};
