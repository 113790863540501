import { useBackendApi } from 'api/hooks/useBackendApi';
import { downloadPlaylistXmlUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';

export const useDownloadPlaylistXml = (playlistId: string, playlistItems: string[]) => {
  const downloadXmlFile = (response: any) => {
    const blob = new Blob([response], {
      type: 'text/xml',
    });
    const blobURL = window.URL.createObjectURL(blob);

    const downloadLink = document.createElement('a');
    downloadLink.target = '_blank';
    downloadLink.href = blobURL;
    downloadLink.download = `playlist-${playlistId}.xml`;
    downloadLink.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    );
    setTimeout(function () {
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  };

  return () =>
    useBackendApi(downloadPlaylistXmlUrl(playlistId), HTTPMethod.POST, downloadXmlFile, playlistItems as any);
};
