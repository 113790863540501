import { UseQueryResult } from '@tanstack/react-query';

import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { transformMatchWithEpisodes } from 'api/match/transformers/match/matchWithEpisodes';
import { liveTaggingSessionAsMatchUrl } from 'api/routes';
import { MatchWithEpisodes } from 'shared/types/match/types';

export const generateLiveSessionAsMatchRef = (liveTaggingSessionId: string) => {
  return [`fetch-live-tagging-session-as-match:${liveTaggingSessionId}`];
};

interface useFetchLiveTaggingSessionAsMatch {
  (matchId: string): UseQueryResult<MatchWithEpisodes>;
}

export const useLiveTaggingSessionAsMatch: useFetchLiveTaggingSessionAsMatch = (liveTaggingSessionId) => {
  const fetchQueryRef = generateLiveSessionAsMatchRef(liveTaggingSessionId);
  const fetchRequest = useFetchRequest<MatchWithEpisodes>({
    queryRef: fetchQueryRef,
    url: liveTaggingSessionAsMatchUrl(liveTaggingSessionId),
    transformer: transformMatchWithEpisodes,
  });

  return { ...fetchRequest };
};
