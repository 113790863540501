import { SvgIcon as MuiSvgIcon, styled, useTheme, type SvgIconProps as MuiSvgIconProps } from '@mui/material';
import { themeBaseSpacing } from 'kognia-ui';

export const ICON_SIZES = {
  xsmall: 2,
  small: 3,
  medium: 4,
  large: 5,
  xlarge: 6,
} as const;

export type IconSizes = keyof typeof ICON_SIZES;

export interface SvgIconProps extends Omit<MuiSvgIconProps, 'onClick' | 'size' | 'disabled'> {
  isButton?: boolean;
  size?: IconSizes;
  onClick?: (event: React.MouseEvent<SVGSVGElement>) => void;
}

const SvgIconContainer = styled(MuiSvgIcon, {
  shouldForwardProp: (prop) => prop !== 'isButton',
})<SvgIconProps>(({ theme, isButton, size }) => ({
  transition: theme.transitions.create(['all'], {
    duration: theme.transitions.duration.shorter,
    easing: theme.transitions.easing.easeOut,
  }),
  strokeLinecap: 'round',
  strokeLinejoin: 'round',

  ...(isButton && {
    cursor: 'pointer',
    position: 'relative',
    userSelect: 'none',
    '&:hover': {
      opacity: '0.5',
    },
    '&:active': {
      opacity: 1,
      top: '1px',
    },
  }),

  ...(size && {
    minWidth: `${ICON_SIZES[size] * themeBaseSpacing}px`,
    minHeight: `${ICON_SIZES[size] * themeBaseSpacing}px`,
    height: `${ICON_SIZES[size] * themeBaseSpacing}px`,
    width: `${ICON_SIZES[size] * themeBaseSpacing}px`,
  }),
}));

export const SvgIcon = ({ children, size = 'medium', htmlColor, color, ...restProps }: SvgIconProps) => {
  const theme = useTheme();

  return (
    <SvgIconContainer
      htmlColor={htmlColor ? htmlColor : color ? undefined : theme.palette.text.primary}
      color={color}
      focusable={'false'}
      size={size}
      role={'img'}
      viewBox={'0 0 24 24'}
      {...restProps}
    >
      {children}
    </SvgIconContainer>
  );
};
