import { atomFamily, useRecoilValue, useSetRecoilState } from 'recoil';

import { RecordingsFilters } from 'shared/types/recording/types';

const playlistMultimatchAppliedFiltersAtom = atomFamily<RecordingsFilters, string>({
  key: 'playlist-multimatch-applied-filters',
  default: {
    recordingIds: [],
  },
});
export const useSetPlaylistMultimatchAppliedFilters = (id: string) => {
  return useSetRecoilState(playlistMultimatchAppliedFiltersAtom(id));
};
export const usePlaylistMultimatchAppliedFilters = (id: string) => {
  return useRecoilValue(playlistMultimatchAppliedFiltersAtom(id));
};
