import { atomFamily } from 'recoil';

export const bulkMode = atomFamily<boolean, string>({
  key: 'bulk-mode',
  default: false,
});

export const bulkSelectedItems = atomFamily<string[], string>({
  key: 'selected-items-bulk-mode',
  default: [],
});
