import { Box, Stack, styled } from '@mui/material';
import { animationDurations, animations, fontSizes } from 'kognia-ui';

import { IconChevronLeft } from 'shared/components/icons/icon-chevron-left';

type PanelProps = {
  isOpen: boolean;
};

export const Panel = styled(Stack, { shouldForwardProp: (prop) => prop !== 'isOpen' })<PanelProps>(
  ({ theme, isOpen }) => ({
    background: isOpen ? theme.palette.common.white : 'transparent',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    maxHeight: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: isOpen ? theme.spacing(2) : 0,
    paddingTop: isOpen ? theme.spacing() : 0,
    transition: 'width 0.2s ease-out',
  }),
);

type PanelContentProps = {
  width: number;
  isOpen: boolean;
};

export const PanelContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isOpen' && prop !== 'width',
})<PanelContentProps>(({ width, isOpen, theme }) => ({
  width: `${width - 32}px`,
  display: isOpen ? 'flex' : 'none',
  flexDirection: 'column',
  overflow: 'hidden',
  maxHeight: '100%',
  paddingBottom: theme.spacing(2),
  animation: `${animations.fadeIn} ${animationDurations.fast} ease-out`,
}));

export const PanelTitle = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  fontSize: fontSizes.default,
  gap: theme.spacing(1),
}));

export const PanelSectionTitle = styled(Stack)(({ theme }) => ({
  background: theme.palette.secondary.light,
  fontSize: fontSizes.default,
  padding: theme.spacing(1),
}));

type IconChevronProps = {
  isOpen: boolean;
};

export const IconChevron = styled(IconChevronLeft, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<IconChevronProps>(({ isOpen }) => ({
  transition: 'transform 0.3s',
  transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
}));

export const ClosedPanelButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  border: `1px solid ${theme.palette.secondary.light}`,
  borderRight: 0,
  borderTopLeftRadius: '8px',
  borderBottomLeftRadius: '8px',
  background: theme.palette.common.white,
  padding: theme.spacing(1, 1, 1, 0),
}));
