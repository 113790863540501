import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';

const sidebarOpenState = atom({
  key: 'isBackofficeSidebarOpen',
  default: true,
});

export const useBackofficeSidebar = () => {
  const [isBackofficeSidebarOpen, setIsBackofficeSidebarOpen] = useRecoilState(sidebarOpenState);

  const toggleBackofficeSidebar = useCallback(
    () => setIsBackofficeSidebarOpen(!isBackofficeSidebarOpen),
    [setIsBackofficeSidebarOpen, isBackofficeSidebarOpen],
  );

  return {
    isBackofficeSidebarOpen,
    toggleBackofficeSidebar,
  };
};
