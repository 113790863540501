import { FiltersList, Playlist, SortDirection } from 'shared/types';

export enum PlaylistSortOptions {
  UPDATED_AT = 'updatedAt',
  NAME = 'name',
}

export enum PlaylistFiltersNames {
  name = 'name',
  competition = 'competition',
  from = 'from',
  to = 'to',
  type = 'type',
  sort = 'sort',
  sortDirection = 'sortDirection',
}

export interface PlaylistsFilters {
  [PlaylistFiltersNames.name]: string;
  [PlaylistFiltersNames.competition]: string[];
  [PlaylistFiltersNames.from]: string | null;
  [PlaylistFiltersNames.to]: string | null;
  [PlaylistFiltersNames.type]: string[];
  [PlaylistFiltersNames.sort]: PlaylistSortOptions;
  [PlaylistFiltersNames.sortDirection]: SortDirection;
}

export interface PlaylistsWithFiltersPage {
  data: {
    nextCursor: number;
    page: { size: number; totalElements: number; totalPages: number; number: number };
    playlists: Playlist[];
    filters: FiltersList;
  };
  nextCursor: number;
}

export interface PlaylistsWithFiltersPageQueryData {
  pages: PlaylistsWithFiltersPage[];
  pageParams: any;
}
