export enum MetricsNames {
  PAGE_VIEW = 'page_view',
  RECORDINGS_OPEN_FILTER = 'recordings_open_filter',
  RECORDINGS_APPLY_FILTER = 'recordings_apply_filter',
  TACTICAL_ANALYSIS_OPEN_FILTER = 'tactical_analysis_open_filter',
  TACTICAL_ANALYSIS_APPLY_FILTER = 'tactical_analysis_apply_filter',
  TACTICAL_ANALYSIS_PLAY_ROW = 'tactical_analysis_play_row',
  TACTICAL_ANALYSIS_PLAY_SELECTED_CLIPS = 'tactical_analysis_play_selected_clips',
  TACTICAL_ANALYSIS_OPEN_EXPORT_XML = 'tactical_analysis_open_export_xml',
  TACTICAL_ANALYSIS_EXPORT_XML = 'tactical_analysis_export_xml',
  TACTICAL_ANALYSIS_OPEN_EXPORT_VIDEO = 'tactical_analysis_open_export_video',
  TACTICAL_ANALYSIS_EXPORT_VIDEO = 'tactical_analysis_export_video',
  TACTICAL_ANALYSIS_CHANGE_ZOOM = 'tactical_analysis_change_zoom',
  OVERLAYS_AVERAGE_FRAME_RATE = 'overlays_average_frame_rate',
}

export interface MetricsData {
  [MetricsNames.PAGE_VIEW]: {
    url: string;
    generic_url: string;
  };
  [MetricsNames.RECORDINGS_OPEN_FILTER]: {
    filter_name: string;
  };
  [MetricsNames.RECORDINGS_APPLY_FILTER]: {
    filter_name: string;
  };
  [MetricsNames.TACTICAL_ANALYSIS_APPLY_FILTER]: {
    filter: string;
  };
  [MetricsNames.TACTICAL_ANALYSIS_CHANGE_ZOOM]: {
    zoom_level: string;
  };
  [MetricsNames.OVERLAYS_AVERAGE_FRAME_RATE]: {
    value: string;
  };
  [MetricsNames.TACTICAL_ANALYSIS_PLAY_ROW]: never;
  [MetricsNames.TACTICAL_ANALYSIS_PLAY_SELECTED_CLIPS]: never;
  [MetricsNames.TACTICAL_ANALYSIS_OPEN_EXPORT_XML]: never;
  [MetricsNames.TACTICAL_ANALYSIS_EXPORT_XML]: never;
  [MetricsNames.TACTICAL_ANALYSIS_OPEN_EXPORT_VIDEO]: never;
  [MetricsNames.TACTICAL_ANALYSIS_EXPORT_VIDEO]: never;
  [MetricsNames.TACTICAL_ANALYSIS_OPEN_FILTER]: never;
}
