import { Box, styled } from '@mui/material';

interface Props {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

export const TabPanelContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 1, 1),
}));

export function TabPanel(props: Props) {
  const { children, value, index, ...other } = props;

  return (
    <Box role='tabpanel' hidden={value !== index} {...other}>
      {value === index && <TabPanelContainer>{children}</TabPanelContainer>}
    </Box>
  );
}

export const TabsPanel = styled(Box)(({ theme }) => ({ overflowY: 'scroll', marginTop: theme.spacing(2) }));
