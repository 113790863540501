import { Box, Typography } from '@mui/material';
import { Colors, fontWeight } from 'kognia-ui';

export const PlayerListHeader = () => {
  return (
    <Box
      sx={{
        padding: 1,
        display: 'grid',
        alignItems: 'center',
        backgroundColor: Colors.background,
        gridTemplateColumns: '32px 1.5fr 170px 170px 100px 2fr',
      }}
    >
      <span />
      <Typography sx={{ fontWeight: fontWeight['500'] }}>Player name</Typography>
      <Typography sx={{ fontWeight: fontWeight['500'] }}>Field position</Typography>
      <Typography sx={{ fontWeight: fontWeight['500'] }}>Side</Typography>
      <Typography sx={{ fontWeight: fontWeight['500'] }}>Number</Typography>
      <Typography sx={{ fontWeight: fontWeight['500'] }}>Game / Date</Typography>
    </Box>
  );
};
