export function iso8601DurationToSeconds(duration: string): number {
  const regex = /P(?:([0-9]+)D)?T?(?:([0-9]+)H)?(?:([0-9]+)M)?(?:([0-9.]+)S)?/;
  const matches = duration.match(regex);

  if (!matches) {
    throw new Error('Invalid ISO 8601 duration format');
  }

  const days = parseInt(matches[1], 10) || 0;
  const hours = parseInt(matches[2], 10) || 0;
  const minutes = parseInt(matches[3], 10) || 0;
  const seconds = parseFloat(matches[4]) || 0;

  return days * 86400 + hours * 3600 + minutes * 60 + seconds; // 86400 seconds in a day
}
