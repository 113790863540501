import map from 'lodash/map';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FiltersSummaryItem from 'pages/tactical-analysis/components/tactical-analysis/filters/components/filters-summary-item';
import {
  defaultEventsEndingAnEpisode,
  defaultEventsStartingAnEpisode,
} from 'pages/tactical-analysis/components/tactical-analysis/filters/default';
import { Zones } from 'pages/tactical-analysis/components/tactical-analysis/filters/types';
import { findTeamById } from 'pages/tactical-analysis/components/tactical-analysis/utils/find-team-by-id';
import { getTeamType } from 'pages/tactical-analysis/components/tactical-analysis/utils/get-team-type';
import ActiveFilter, { DefaultVariant } from 'shared/components/active-filter';
import ActiveFilterTeam from 'shared/components/active-filter-team';
import ActiveFilters from 'shared/components/active-filters';
import { RecordingsFiltersEvents } from 'shared/types/recording/types';
import { MatchTeams } from 'shared/types/teams/types';

interface Props {
  contained?: boolean;
  filters: RecordingsFiltersEvents;
  showCloseButton?: boolean;
  teams: MatchTeams;
  onUpdate?: (filters: RecordingsFiltersEvents) => void;
  hasTeamFocus?: boolean;
}

const EventsEndingEpisodeSummary = ({ teams, filters, contained, showCloseButton, onUpdate, hasTeamFocus }: Props) => {
  const { t } = useTranslation();

  if (JSON.stringify(filters) === JSON.stringify(defaultEventsEndingAnEpisode)) return null;

  const clearTeamFilter = () => {
    onUpdate && onUpdate({ ...filters, teams: [] });
  };

  const clearTacticalFilter = () => {
    onUpdate && onUpdate({ ...filters, event: '' });
  };

  const updateZoneFilter = (zone: Zones) => {
    onUpdate && onUpdate({ ...filters, zones: [...filters.zones, zone] });
  };

  const clearAllFilters = () => {
    onUpdate && onUpdate(defaultEventsStartingAnEpisode);
  };

  return (
    <FiltersSummaryItem title={t('recordings:filters.tabs.events-ending-episode')}>
      <ActiveFilters>
        {filters.teams &&
          filters.teams.map((teamId) => (
            <ActiveFilterTeam
              hasTeamFocus={hasTeamFocus}
              key={teamId}
              onClose={clearTeamFilter}
              showCloseButton={showCloseButton}
              contained={contained}
              team={findTeamById(teams, teamId)}
              teamType={getTeamType(teamId, teams)}
            />
          ))}
        {filters.event && (
          <ActiveFilter
            onClose={clearTacticalFilter}
            closeButton={showCloseButton}
            contained={contained}
            variant={DefaultVariant.DEFAULT}
            title={t(`fundamentals:ending-event-fundamentals.${filters.event}`)}
          />
        )}
        {map(filters.zones, (value, idx) =>
          value ? (
            <ActiveFilter
              onClose={() => {
                const zone = Number(idx) as Zones;
                updateZoneFilter(zone);
              }}
              closeButton={showCloseButton}
              contained={contained}
              key={idx}
              variant={DefaultVariant.DEFAULT}
              title={`${t('recordings:filters.zone')} ${idx}`}
            />
          ) : null,
        )}
        {showCloseButton && <ActiveFilter onClose={clearAllFilters} closeButton variant={DefaultVariant.DEFAULT} />}
      </ActiveFilters>
    </FiltersSummaryItem>
  );
};

export default EventsEndingEpisodeSummary;
