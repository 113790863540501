export enum RecordingsFiltersActions {
  APPLY_FILTER = 'APPLY_FILTER',
  REMOVE_FILTER = 'REMOVE_FILTER',
}

export type ApplyFilterPayloadType = {
  key: string;
  selectedFilters: string[];
};

export type ApplyFilterAction = {
  type: RecordingsFiltersActions.APPLY_FILTER;
  payload: ApplyFilterPayloadType;
};

export type RemoveFilterAction = {
  type: RecordingsFiltersActions.REMOVE_FILTER;
  payload: string;
};

export type FilterActions = ApplyFilterAction | RemoveFilterAction;

export const filtersReducer = (
  state: { [key: string]: string[] },
  action: FilterActions,
): { [key: string]: string[] } => {
  switch (action.type) {
    case RecordingsFiltersActions.APPLY_FILTER:
      state[action.payload.key] = action.payload.selectedFilters;
      return { ...state };
    case RecordingsFiltersActions.REMOVE_FILTER:
      state[action.payload] = [];
      return { ...state };
    default:
      return state;
  }
};
