export interface MatchTeams {
  homeTeam: MatchTeam;
  opponentTeam: MatchTeam;
}

export interface MatchTeam {
  id: string;
  name: string;
  logo: string;
  players: TeamPlayer[];
}

export interface TeamPlayer {
  id: string;
  positionLine?: number;
  positionWing?: number;
  photo?: string;
  substitutedBy?: number;
  name: string;
  number?: number;
}

export enum MatchTeamTypes {
  HOME = 'homeTeam',
  OPPONENT = 'opponentTeam',
}
