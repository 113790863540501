import { atomFamily, useRecoilValue, useSetRecoilState } from 'recoil';

const timelineInitialTimeAtom = atomFamily<number, string>({
  key: 'tactical-analysis-initial-time',
  default: undefined,
});

export const useSetTimelineInitialTime = (id: string) => {
  return useSetRecoilState(timelineInitialTimeAtom(id));
};

export const useTimelineInitialTime = (id: string) => {
  return useRecoilValue(timelineInitialTimeAtom(id));
};
