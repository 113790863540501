import { multiply } from 'mathjs';
import { Homography } from '../types';

// Function that given a 3x3 homography matrix it returns the matrix upscale by a value
export const scaleMatrix = (matrix: number[][], matrixScale: number, videoScale: number): Homography => {
  const upscaleMatrix = [
    [1 / matrixScale, 0, 0],
    [0, 1 / matrixScale, 0],
    [0, 0, 1],
  ];

  const videoScaleMatrix = [
    [videoScale, 0, 0],
    [0, videoScale, 0],
    [0, 0, 1],
  ];

  const adjustedByVideoScale = multiply(videoScaleMatrix, matrix);

  return multiply(adjustedByVideoScale, upscaleMatrix) as Homography;
};
