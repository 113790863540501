import { Button, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import IconFilter from 'shared/components/icons/icon-filter';
import SortBy from 'shared/components/sort-by';
import { SortDirection } from 'shared/types/filters/types';

interface Props {
  handleShowFiltersModal: () => void;
  filtering: boolean;
  handleSortChange: (newOrder: SortDirection) => void;
}

export const TagRecordingFilters = ({ handleShowFiltersModal, filtering, handleSortChange }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button
          startIcon={<IconFilter color={filtering ? 'primary' : 'secondary'} size='small' />}
          onClick={handleShowFiltersModal}
          size='small'
          color={filtering ? 'primary' : 'secondary'}
        >
          {t('tagging-tool:tagging-recording.filter').toUpperCase()}
        </Button>
      </Grid>
      <Grid item>
        <SortBy onSort={handleSortChange} />
      </Grid>
    </Grid>
  );
};
