import { Button } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog } from 'shared/components/dialog';
import { DialogActions } from 'shared/components/dialog/dialog-actions';
import { DialogButtonContainer } from 'shared/components/dialog/dialog-button-container';
import { DialogContent } from 'shared/components/dialog/dialog-content';
import { DialogContentText, DialogTextVariants } from 'shared/components/dialog/dialog-content-text';
import { DialogHeader } from 'shared/components/dialog/dialog-header';
import IconKeypad from 'shared/components/icons/icon-keypad';

export type KeypadConfirmModalProps = {
  open: boolean;
  name?: string;
  onDelete: () => void;
  onCancel: () => void;
};

export const KeypadRemoveModal = ({ open, name, onDelete, onCancel }: KeypadConfirmModalProps) => {
  const { t } = useTranslation();

  const handleSubmit = useCallback(() => {
    onDelete();
  }, [onDelete]);

  return (
    <Dialog open={open} onClose={onCancel} fullWidth maxWidth='sm'>
      <DialogHeader icon={<IconKeypad size='small' color='error' />}>
        {t('tagging-tool:keypad.delete-modal-title')}
      </DialogHeader>
      <DialogContent>
        <DialogContentText textVariant={DialogTextVariants.Primary}>
          {t('tagging-tool:keypad.delete-modal-main-text', { name })}
        </DialogContentText>
        <DialogContentText>{t('tagging-tool:keypad.delete-modal-text1')}</DialogContentText>
        <DialogContentText>{t('tagging-tool:keypad.delete-modal-text2')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <DialogButtonContainer>
          <Button onClick={onCancel} variant='outlined' color='secondary'>
            {t('common:actions.cancel')}
          </Button>
        </DialogButtonContainer>
        <DialogButtonContainer>
          <Button onClick={handleSubmit} variant='contained' color='error'>
            {t('common:actions.remove')}
          </Button>
        </DialogButtonContainer>
      </DialogActions>
    </Dialog>
  );
};
